import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const wrapRootElement = ({ element }) => {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>{element}</LocalizationProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  const Layout = element.type.Layout ?? React.Fragment;
  return Layout === React.Fragment ? (
    <Layout>{element}</Layout>
  ) : (
    <Layout {...props}>{element}</Layout>
  );
};
